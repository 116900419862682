.form {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;

  @media screen and (max-width: 768px) {
    max-width: inherit;
  }

  .formField {
    position: relative;
    display: flex;
    justify-content: center;
    transition: all .2s;

    .label {
      transform: translateY(-60%);
      position: absolute;
      left: 18px;
      transition: all 0.2s ease;
      color: #757575;
      font-size: 0.75rem;
      top: 0;
      padding: 0 10px;
      background: #fff;
    }

    .input {
      width: 100%;
      height: 44px;
      padding: 5px 10px 0 17px;
      border: 1px solid #ccc;
      border-radius: 50px;
      outline: none;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
    }

    .textarea {
      width: 100%;
      height: 100px;
      padding: 5px 10px 0 17px;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 20px;
      outline: none;
    }
  }
}
.title {
  width: fit-content;
  text-wrap: nowrap;
}

.formWrapper {
  display: flex;
  flex-flow: column;
  width: 50%;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-flow: column;
    width: 100%;
  }
}

.invalidMessage {
  position: absolute;
  left: 18px;
  color: red;
  font-size: 0.7rem;
  background: #ffffff;
  padding: 0 10px;
  bottom: -5px;
}

.successMessage, .errorMessage, .alertMessage {
  color: green;
  font-size: 1rem;
  margin-top: 5px;
  text-align: center;
}

.errorMessage {
  color: red;
}

.alertMessage {
  color: #ff8c00;
}
