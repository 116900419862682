@import "mixins";

.sellerconf{
  min-height: 400px;
  width: 100%;
  margin: 75px 0;

  @media screen and (max-width: 1100px){
    margin: 50px 0;
  }

  .sellerconf-upper{
    .sellerconf-upper__items{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(5, 1fr); // три колонки
      grid-template-rows: auto; // высота строки будет автоматической
      gap: 25px; // отступы между элементами

      @media screen and (max-width: 1100px){
        grid-template-columns: repeat(4, 1fr);
      }

      @media screen and (max-width: 600px){
        gap: 15px;
      }

      .sellerconf-upper__item{
        position: relative;
        background-color: #f2f2f2;
        padding: 0 16% ;
        border-radius: 50px;
        height: 210px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: end;
        overflow: hidden;
        flex-shrink: 1;
        transition: all .6s ease;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 28px;

        @media screen and (max-width: 600px){
          height: 140px;
        }

        .sellerconf-upper__item-title{
          position: absolute;
          bottom: 20px;
          right: 25px;
          span {
            font-size: 28px;
            color: #fff;
            font-weight: 700;
            transition: all .4s ease;
            text-shadow: 2px 2px 7px #000000;

            @media screen and (max-width: 1500px){
              font-size: 24px;
            }
            @media screen and (max-width: 1400px){
              font-size: 20px;
            }
            @media screen and (max-width: 1300px){
              font-size: 18px;
            }
          }
        }

        &.logo{
          grid-column: span 2; // растягивает элемент на две колонки
          grid-row: span 1; // растягивает элемент на две строки
          padding: 0;
          transition: all .4s ease;
          background-image: url('../img/sellerconf/sellerconf_logo.png');
          background-position: center;
          background-size: 105%;
          background-repeat: no-repeat;
          @media screen and (max-width: 600px){
            grid-column: span 4; // растягивает элемент на две колонки
          }

          @media screen and (min-width: 601px) and (max-width: 800px){
            -webkit-background-size: 140%;
            background-size: 140%;
          }

          .sellerconf-upper__item-img{
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }

        @include hover-bg-size(100%, 108%);

        &.marketplace{
          grid-column: span 2; // растягивает элемент на две колонки
          grid-row: span 1; // растягивает элемент на две строки
          background-image: url('../img/sellerconf/marketplace.png');
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat;

          @include hover-bg-size(100%, 108%);

          @media screen and (max-width: 1100px){
            display: none;
          }
        }


        &.seller, &.manufacturer, &.b2b, &.distributor-frame {
          background-image: url('../img/sellerconf/seller.png');
          background-position: center;
          background-size: 130%;
          background-repeat: no-repeat;
          .sellerconf-upper__item-title span {
            font-size: 24px;
          }
          @include hover-bg-size(130%, 138%);

          @media screen and (max-width: 1500px){
            @include hover-bg-size(150%, 160%);
          }
          @media screen and (max-width: 1320px){
            @include hover-bg-size(180%, 188%);
            .sellerconf-upper__item-title span {
              font-size: 14px;
            }
          }
          @media screen and (max-width: 1100px){
            display: none;
          }

        }

        &.b2b{
          background-image: url('../img/sellerconf/b2b.png');
        }

        &.distributor-frame{
          background-image: url('../img/sellerconf/distributor.png');
        }

        &.manufacturer{
          background-image: url('../img/sellerconf/manufacturer.png');
        }

        &.description{
          text-align: center;
          text-wrap: balance;
          grid-column: span 2; // растягивает элемент на две колонки
          grid-row: span 1; // растягивает элемент на две строки
          font-weight: 700;
          text-transform: uppercase;
          padding: 0;
          color: #fff;
          background-color: #1D1D1D;
          @media screen and (max-width: 600px){
            grid-column: span 4; // растягивает элемент на две колонки
          }

          @include hover-bg-size(100%, 108%);

          .sellerconf-upper__item-title{
            position: static;
            span > span{
              color: #D310F2;
              text-shadow: unset;
              transition: all .4s ease;

              @media screen and (max-width: 1600px){
                font-size: 30px;
              }

              @media screen and (max-width: 1500px){
                font-size: 26px;
              }

              @media screen and (max-width: 1100px){
                font-size: 22px;
              }

              @media screen and (max-width: 800px){
                font-size: 18px;
              }

              @media screen and (max-width: 600px){
                font-size: 24px;
              }

              @media screen and (max-width: 500px){
                font-size: 22px;
              }
            }
          }
        }
      }
    }
  }



  .sellerconf-lower{
    margin-top: 100px;
    transition: all .4s ease;
    @media screen and (max-width: 1100px){
      margin-top: 40px;
    }

    .sellerconf-lower__head{
      display: flex;
      margin-bottom: 50px;
      justify-content: space-between;

      .sellerconf-lower__favorite{
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        @media screen and (max-width: 600px){
          span{
            display: none;
          }
        }
        svg{
          width: 30px;
          height: 30px;
          fill: #fe2942;
        }
      }
    }

    .sellerconf-lower__title{
      font-size: 40px;
      font-weight: 600;
      transition: all .4s ease;

      @media screen and (max-width: 1100px){
        font-size: 28px;
      }

    }
    .sellerconf-lower__items{
      display: grid;
      grid-template-columns: repeat(5, 1fr); // три колонки
      grid-template-rows: auto; // высота строки будет автоматической
      gap: 25px;

      @media screen and (max-width: 1360px){
        grid-template-columns: repeat(4, 1fr);
      }

      @media screen and (max-width: 1100px){
          grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: 840px){
          grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 600px){
          grid-template-columns: repeat(1, 1fr);
      }

      .sellerconf-lower__item{
        padding: 30px 20px;
        border-radius: 30px;
        border: 1px solid #cbcbcb;
        grid-column: span 1; // растягивает элемент на две колонки
        grid-row: span 1; // растягивает элемент на две строки
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        color: #383838;
        min-height: 260px;

        .sellerconf-lower__item-name{
          min-height: 40px;
        }

        span{
          word-break: break-all;
        }

        @media screen and (max-width: 600px){
          min-height: 210px;
          padding: 15px;
          gap: 10px;
        }
        .sellerconf-lower__item-action{
          position: relative;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          gap: 15px;
          min-height: 90px;
          .sellerconf-lower__item-qr{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            div{
              div{
                width: 30px;
                height: 30px;
                svg{
                  width: 100%;
                  height: 100%;
                }
              }

            }
          }
        }

        .sellerconf-lower__item-contacts{
          .sellerconf-lower__item-contacts__text{
            display: flex;
            flex-direction: column;
            gap: 10px;
            min-height: 77px;
          }
          .sellerconf-lower__item-contacts__socials{
            display: flex;
            gap: 10px;
            margin-top: 20px;
            .sellerconf-lower__item-contacts__socials-item{
              img{
                width: 25px;
                height: 25px;
              }
            }
          }
        }
        .sellerconf-lower__item-btn{
          display: flex;
          justify-content: center;
          align-items: center;
          span{
            padding: 10px 20px;
            border: 2px solid #6c6cc7;
            border-radius: 50px;
            color: #6c6cc7;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.seller-conf-form {
  display: flex;
  gap: 5%;
  margin-top: 45px;



  @media screen and (max-width: 768px) {
    flex-flow: column;
    align-items: center;
  }
}
