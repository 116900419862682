.sellerConfText {
  max-width: 50%;
  padding-top: 40px;
  line-height: 165%;
  text-wrap: pretty;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  };

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;

    li {
      list-style: none;
      position: relative;
      padding-left: 20px;
      margin-bottom: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        background-color: #1D1D1D;
        border-radius: 50%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}