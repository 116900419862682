.i_modal-title{
  margin-bottom: 30px;
  font-size: 18px;
}

.i_modal-subtitle{
  margin-bottom: 30px;
  font-size: 14px
}

.i_texpo-register{
  position: relative;
  width: 100%;
  height: 100%;
  padding-right: 30px;
  overflow-y: auto;
  .i_texpo-register-items{
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .i_texpo-register-button{
    position: relative;
    bottom: -20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .i_a_form-item{
      width: 150px;
    }
  }
}

.i_modal-catalog-category{
  border: 2px solid #f1f1f1;
  width: 100%;
  padding: 10px;
  font-size: 1.125rem;
  border-radius: 15px;
  height: 100%;

  .i_catalog-tools {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 2px solid #f1f1f1;
    padding-bottom: 8px;

    .i_multitools {
      height: unset;
      border: 0;
      gap: 10px;
      padding: 0;

      .i_multitools-item {
        width: 24px;
        height: 24px;
        cursor: pointer;

        &.disabled {
          opacity: 1;

          svg {
            path {
              fill: #b7b7b7;
              transition: all .4s ease;
            }
          }
        }

        div {
          width: 24px;
          height: 24px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .i_catalog-tools-right {
      display: flex;
      gap: 10px;
    }

    .i_tools-item {
      cursor: pointer;

      div {
        width: 24px;
        height: 24px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      &.disabled {
        opacity: .5;
        pointer-events: none;
      }
    }
  }

  .i_catalog-category-items {
    display: flex;
    flex-direction: column;
    height: calc(75vh - 15px);
    overflow-y: auto;
    //background-color: #f8f8f8;
    padding: 0 15px 15px;
    margin-top: 15px;

    &::-webkit-scrollbar {
      width: 2px;
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(118, 118, 118, 0.3);
    }

    &:hover{
      &::-webkit-scrollbar-thumb {
        background-color: #767676FF;
        transition: all .4s ease;
      }
    }


    .i_catalog-category-item-level-line {
      position: absolute;
      left: 1px;
      width: 1px;
      background-color: #d1d1d1;
      height: calc(100% - 32px);
      top: 15px;
    }

    .i_catalog-category-item {
      position: relative;
      font-size: 0.815rem;

    }

    .i_catalog-category-item-title {
      padding: 10px 0;
      position: relative;
      transition: all .4s ease;

      &:hover {
        color: #fe2942;
        cursor: pointer;
      }

      &.selected {
        color: #fe2942;
      }

      .i_catalog-title-circle {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
        height: 14px;
        border-radius: 100%;
        left: -20px;
        background-color: #ebebeb;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        z-index: 1;

        img {
          width: 6px;
          height: 6px;
          opacity: .6;
        }

        &:not(.last-child) {
          &.active {
            &::after {
              content: "";
              width: 6px;
              height: 6px;
              background-color: #767676;
              border-radius: 100%;
              position: absolute;
              top: 50%;
              left: 4px;
              transform: translate(0%, -50%);
            }
          }
        }
      }
    }

    .i_catalog-category-item-level-0 {
      padding-left: 15px;
      position: relative;

      .i_catalog-category-item-level-1 {
        display: none;
      }

      &.active {
        max-height: 10000px;

        .i_catalog-category-item-level-1 {
          display: block;
        }
      }
    }

    @for $i from 1 through 10 {
      .i_catalog-category-item-level-#{$i} {
        position: relative;

        .i_catalog-category-item-level-#{$i + 1} {
          display: none;
        }

        &.active {
          max-height: 10000px;

          .i_catalog-category-item-level-#{$i + 1} {
            display: block;
          }
        }
      }
    }

  }
}

//CATALOG

.i_catalog-title{
  font-size: 1.25rem;
  margin-bottom: 25px;
}

.i_catalog-page {
  width: 100%;
  height: 96%;

  .i_catalog-page-empty,
  .i_catalog-product-empty,
  .i_catalog-category-empty {
    margin: 20px 0;
    position: relative;
    width: 400px;
    display: flex;
    gap: 15px;
    font-size: 0.815rem;
    flex-direction: column;
    padding: 20px 15px;
    border: 2px solid #f1f1f1;
    border-radius: 15px;
    justify-content: center;

    a {
      text-decoration: none;
      color: #0184db;
    }

    img {
      width: 25px;
      height: 25px;
    }

    .i_catalog-page-empty-title {
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }

  .i_catalog-category-empty {
    border: 2px solid #f1f1f1;
    width: 100%;
  }

  .i_catalog-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 14px;
    transition: all .4s ease;

    &.distributors-catalogs {
      .i_catalog-item {
        .i_catalog-item-name {
          margin-top: 20px;
        }
      }
    }

    .i_catalog-item {
      width: calc(100% / 5 - 12px);

      padding: 20px 15px;
      border: 2px solid #f1f1f1;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      min-height: 340px;
      gap: 40px;
      transition: all .4s ease;
      color: #000;

      a {
        text-decoration: none;
        color: #000;
      }

      &:hover {
        cursor: pointer;

        &.add-item {
          svg {
            width: 70px;
            height: 70px;
            fill: #fe2942;
          }
        }

        &:hover {
          .i_catalog-item-name {
            a, span {
              color: #fe2942;
            }
          }
        }
      }

      &.add-item {
        cursor: pointer;

        svg {
          width: 70px;
          height: 70px;
          fill: #9a9a9a;
          transition: all .4s ease;
        }
      }
    }

    .i_catalog-item-name {
      a, span {
        font-size: 1.3rem;
        transition: all .4s ease;
        text-decoration: none;
        color: #000;
      }
    }

    .i_catalog-item-date {
      font-size: 0.815rem;
      color: #b7b7b7;
    }

    .i_catalog-item-code {
      color: #b7b7b7;
      position: relative;

      .i_clipboard {
        right: -45px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .i_catalog-item-stats {
      display: flex;
      font-size: 0.815rem;
      justify-content: center;
      gap: 15px;
      width: 100%;

      .i_catalog-item-category-active,
      .i_catalog-item-product-active {
        padding: 5px 15px;
        border-radius: 50px;
        background: #f8f8f8;
      }
    }

    .i_catalog-item-btn {
      display: flex;
      gap: 50px;

      .i_catalog-item-btn-edit {
        svg {
          width: 27px;
          height: 27px;
          fill: #7b7b7b;
          cursor: pointer;
          transition: all .4s ease;

          &:hover {
            fill: #fe2942;
          }
        }
      }

      .i_catalog-item-btn-trash {
        svg {
          fill: #7b7b7b;
          cursor: pointer;
          transition: all .4s ease;

          &:hover {
            fill: #fe2942;
          }
        }
      }
    }
  }

  .i_catalog-page-skeleton {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 14px;
  }

  .i_catalog-page-in-skeleton {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .react-loading-skeleton:nth-child(1) {
      width: 20%;
    }

    .react-loading-skeleton:nth-child(2) {
      width: 79%;
    }
  }

  .i_catalog-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      height: auto;
    }

    .i_category-list-mobile {
      display: none;
      padding: 10px 20px;
      border: 2px solid #f1f1f1;
      border-radius: 15px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;

      &:hover {
        cursor: pointer;
      }

      div{
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          fill: #fe2942;
          width: 14px;
          height: 14px;
        }
      }

      @media screen and (max-width: 1024px) {
        display: flex;
      }
    }

    .i_catalog-category {
      border: 2px solid #f1f1f1;
      width: 20%;
      padding: 10px;
      font-size: 1.125rem;
      border-radius: 15px;
      height: 100%;

      @media screen and (max-width: 1024px) {
        display: none;
      }

      .i_catalog-tools {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        border-bottom: 2px solid #f1f1f1;
        padding-bottom: 8px;

        .i_multitools {
          height: unset;
          border: 0;
          gap: 10px;
          padding: 0;

          .i_multitools-item {
            width: 24px;
            height: 24px;
            cursor: pointer;

            &.disabled {
              opacity: 1;

              svg {
                path {
                  fill: #b7b7b7;
                  transition: all .4s ease;
                }
              }
            }

            div {
              width: 24px;
              height: 24px;

              svg {
                width: 24px;
                height: 24px;
              }
            }
          }
        }

        .i_catalog-tools-right {
          display: flex;
          gap: 10px;
        }

        .i_tools-item {
          cursor: pointer;

          div {
            width: 24px;
            height: 24px;

            svg {
              width: 24px;
              height: 24px;
            }
          }

          &.disabled {
            opacity: .5;
            pointer-events: none;
          }
        }
      }

      .i_catalog-category-items {
        display: flex;
        flex-direction: column;
        height: 80vh;
        overflow-y: auto;
        //background-color: #f8f8f8;
        padding: 15px;


        .i_catalog-category-item-level-line {
          position: absolute;
          left: 1px;
          width: 1px;
          background-color: #d1d1d1;
          height: calc(100% - 32px);
          top: 15px;
        }

        .i_catalog-category-item {
          position: relative;
          font-size: 0.815rem;

        }

        .i_catalog-category-item-title {
          padding: 10px 0;
          position: relative;
          transition: all .4s ease;

          &:hover {
            color: #fe2942;
            cursor: pointer;
          }

          &.selected {
            color: #fe2942;
          }

          .i_catalog-title-circle {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 14px;
            height: 14px;
            border-radius: 100%;
            left: -20px;
            background-color: #ebebeb;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            z-index: 1;

            img {
              width: 6px;
              height: 6px;
              opacity: .6;
            }

            &:not(.last-child) {
              &.active {
                &::after {
                  content: "";
                  width: 6px;
                  height: 6px;
                  background-color: #767676;
                  border-radius: 100%;
                  position: absolute;
                  top: 50%;
                  left: 4px;
                  transform: translate(0%, -50%);
                }
              }
            }
          }
        }

        .i_catalog-category-item-level-0 {
          padding-left: 15px;
          position: relative;

          .i_catalog-category-item-level-1 {
            display: none;
          }

          &.active {
            max-height: 10000px;

            .i_catalog-category-item-level-1 {
              display: block;
            }
          }
        }

        @for $i from 1 through 10 {
          .i_catalog-category-item-level-#{$i} {
            position: relative;

            .i_catalog-category-item-level-#{$i + 1} {
              display: none;
            }

            &.active {
              max-height: 10000px;

              .i_catalog-category-item-level-#{$i + 1} {
                display: block;
              }
            }
          }
        }

      }
    }


    .i_catalog-product {
      width: 79%;
      overflow-x: auto;

      @media screen and (max-width: 1280px){
        .i_catalog-product-head,
        .i_catalog-product-items{
          min-width: unset !important;
        }
      }

      @media screen and (max-width: 1024px) {
        width: 100%;
        margin-bottom: 15px;
        &.favorite-list{

          .i_catalog-product-items{
            max-height: calc(100vh - 110px);
          }
          .i_catalog-product-item{
            grid-template-columns: repeat(1, 1fr) !important;
            padding: 10px 0 !important;
            .i_catalog-product-item-name{
              grid-column: span 1;
              width: 90%;
            }
            .i_catalog-product-item-distributor-name{
              grid-column: span 1;
              width: 90%;
            }
          }
        }
      }

      .i_catalog-product-head {
        display: flex;
        align-items: center;
        font-size: 0.815rem;
        background-color: #f8f8f8;
        padding: 10px 15px;
        border-radius: 10px;

        @media screen and (max-width: 1024px){
          min-width: 100%;
          margin-bottom: 5px;
          padding: 3px;
          border-radius: 25px;
          *{
            display: none;
          }
        }

        .i_catalog-product-head-item {
          width: 20%;
          padding: 0 10px;

          &.name {
            width: 40%;
          }

          &.active {
            width: 13%;
            display: flex;
            justify-content: center;
          }

          &.id {
            width: 12%;
            padding: 0 10px;
          }

          &.article,
          &.quantity {
            width: 13%;
          }

          &.price,
          &.rrp {
            width: 15%;
          }
        }
      }

      .i_catalog-product-items {
        display: flex;
        flex-direction: column;
        overflow: auto;
        max-height: 83vh;

        @media screen and (max-width: 1024px){
          min-width: 100%;
          max-height: calc(100vh - 170px);
          padding-bottom: 70px;
        }

        //gap: 30px;
        .i_catalog-product-item {
          position: relative;
          display: flex;
          padding: 10px 15px;
          align-items: center;
          font-size: 0.815rem;
          border-bottom: 1px solid #ebebeb;
          cursor: pointer;
          transition: all .4s ease;

          &:hover {
            background-color: #f8f8f8;
          }

          @media screen and (max-width: 1024px){
            display: grid;
            grid-template-columns: repeat(14, 1fr); // три колонки
            grid-template-rows: auto; // высота строки будет автоматической
            gap: 15px 10px;
            &:hover {
              background-color: unset;
            }
          }

          .i_catalog-product-item-favorite{
            position: absolute;
            right: 40px;
            width: 23px;
            padding: 0;
            div{
              width: 23px;
              height: 23px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              &.icon-filled{
                svg{
                  fill: #fe2942;
                }
              }
              svg{
                  width: 23px;
              }
            }
          }
        }

        .i_catalog-product-more {
          width: 100%;
          padding: 20px 0;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            color: #fff;
            background: linear-gradient(90deg, #6c6cc7, #5757b7);
            padding: 10px 20px;
            border-radius: 80px;
            cursor: pointer;
            width: 15%;
            transition: all 0.4s ease;
            opacity: 1;
            display: flex;
            justify-content: center;
            @media screen and (max-width: 1024px){
              width: 50%;
            }
          }
        }

        .i_catalog-product-item > * {
          width: 20%;
          padding: 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;

          @media screen and (max-width: 1024px){
            display: none;
          }
        }

        .i_catalog-product-item-name {
          width: 40%;
        }

        .i_catalog-product-item-active {
          width: 13%;
          display: flex;
          justify-content: center;

          .item-circle {
            width: 10px;
            height: 10px;
            min-width: 10px;
            min-height: 10px;
            border-radius: 50%;
            background-color: #cdcdcd;

            &.active {
              background-color: #01c716;
            }
          }
        }

        .i_catalog-product-item-id {
          width: 12%;
        }

        .i_catalog-product-item-article {
          width: 13%;
        }

        .i_catalog-product-item-price,
        .i_catalog-product-item-rrp {
          width: 15%;
        }

        .i_catalog-product-item-quantity {
          width: 13%;
        }

        .i_catalog-product-item-title{
          display: none;
        }

        @media screen and (max-width: 1024px){
          .i_catalog-product-item-active{
            width: 100%;
            grid-column: span 1; // растягивает элемент на две колонки
            grid-row: span 1; // растягивает элемент на две строки
            justify-content: start;
            padding: 0;
          }
          .i_catalog-product-item-name,
          .i_catalog-product-item-price,
          .i_catalog-product-item-quantity{
            display: flex;
            gap: 5px;
          }

          .i_catalog-product-item-quantity{
            width: 100%;
            grid-column: span 7; // растягивает элемент на две колонки
            padding: 0;
          }

          .i_catalog-product-item-price{
            width: 100%;
            grid-column: span 7; // растягивает элемент на две колонки
          }

          .i_catalog-product-item-name{
            grid-column: span 13; // растягивает элемент на две колонки
            grid-row: span 1; // растягивает элемент на две строки
          }

          .i_catalog-product-item-title{
            display: block;
          }

          .i_catalog-product-item-name{
            width: 100%;
          }
          .i_catalog-product-item-favorite{
            display: block;
            right: 15px !important;
            top: 6px;
            bottom: unset !important;
          }
          .i_catalog-product-item-distributor-name{
            display: flex
          }
        }
      }
    }
  }
}

.i_product-detail {
  height: 100%;

  .i_product-detail-title {
    font-size: 1.2rem;
    width: 90%;

    @media screen and (max-width: 1024px){
      width: 85%;
    }
  }

  .i_product-detail-favorite{
    position: absolute;
    right: 50px;
    top: 20px;
    width: 32px;
    padding: 0;
    @media screen and (max-width: 1024px){
      right: 25px;
    }
    div{
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.icon-filled{
        svg{
          fill: #fe2942;
        }
      }
      svg{
          width: 32px;
      }
    }
  }

  .i_product-detail-content {
    margin-top: 40px;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    padding-right: 25px;
    justify-content: space-between;
    height: 95%;
    align-content: flex-start;
    overflow: auto;

    @media screen and (max-width: 1024px){
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 25px;
      margin-top: 25px;
      padding-bottom: 110px;
    }

    @media screen and (max-width: 768px){
      gap: 25px;
      padding-right: 10px;
    }

    .i_product-detail-content-title {
      font-size: 1.3rem;
    }

    .i_product-detail-content-left {
      position: relative;
      width: 30%;
      @media screen and (max-width: 1024px){
        width: 100%;
        padding-bottom: 25px;
      }
    }

    .i_product-detail-images-edit{
      position: absolute;
      z-index: 3;
      top: 2px;
      right: 2px;
      border-radius: 15px;
      padding: 20px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      cursor: pointer;
      opacity: 0;
      visibility: hidden;
      transition: all .4s ease;

      .i_product-detail-edit{
        div{
          width: 24px;
          height: 24px;
          svg{
            width: 100%;
            height: 100%;
            transition: all .4s ease;
          }
        }
        &:hover{
          svg{
            fill: #fe2942;
          }
        }
      }

    }

    .i_product-detail-content-right {
      width: 65%;
      @media screen and (max-width: 1024px){
        width: 100%;
      }
    }

    .i_product-detail-images {
      width: 100%;
      height: max-content;

      &:hover{
        .i_product-detail-images-edit{
          opacity: 1;
          visibility: visible;
        }
      }

      .i_product-detail-noimage {
        height: 400px;
        width: 100%;
        border: 1px solid #e1dfe1;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 50%;
          }
        }
      }
    }

    .i_product-detail-props {
      width: 100%;
      height: max-content;

      .i_product-props-empty {
        margin: 15px 0;
        position: relative;
        width: 400px;
        display: flex;
        gap: 15px;
        font-size: 0.815rem;
        flex-direction: column;
        padding: 20px 15px;
        border: 2px solid #f1f1f1;
        border-radius: 15px;
        justify-content: center;

        @media screen and (max-width: 768px){
          width: 100%;
        }

        a {
          text-decoration: none;
          color: #0184db;
        }

        img {
          width: 25px;
          height: 25px;
        }

        .i_product-props-empty-title {
          display: flex;
          gap: 20px;
          align-items: center;
        }
      }

      .i_product-detail-props-title {
        font-size: 1.4rem;
        margin-bottom: 20px;
      }

      .i_product-detail-props-items {
        display: flex;
        flex-direction: column;
        font-size: 0.875rem;

        .i_product-detail-props-item {
          display: flex;
          justify-content: space-between;
          //padding: 10px 0;
          &:first-child {
            padding-top: 0;

            .i_product-detail-props-item-value {
              border-radius: 15px 15px 0 0;
            }
          }

          &:not(:last-child) {
            border-bottom: 1px solid #e1dfe1;
          }

          &:last-child {
            .i_product-detail-props-item-value {
              border-radius: 0 0 15px 15px;
            }
          }

          .i_product-detail-props-item-name {
            width: 49%;
            padding: 10px 0;
          }

          .i_product-detail-props-item-value {
            width: 49%;
            padding: 10px 15px;
            background-color: #f8f8f8;
            overflow-wrap: break-word;
          }
        }
      }
    }

    .i_product-detail-description {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 40px;
      gap: 20px;

      @media screen and (max-width: 1024px){
        margin-top: 20px;
      }

      @media screen and (max-width: 560px){
        font-size: 0.875rem;
      }

      .i_product-description-empty {
        margin: 15px 0;
        position: relative;
        width: 400px;
        display: flex;
        gap: 15px;
        font-size: 0.815rem;
        flex-direction: column;
        padding: 20px 15px;
        border: 2px solid #f1f1f1;
        border-radius: 15px;
        justify-content: center;

        @media screen and (max-width: 560px){
          width: 100%;
        }

        a {
          text-decoration: none;
          color: #0184db;
        }

        img {
          width: 25px;
          height: 25px;
        }

        .i_product-description-empty-title {
          display: flex;
          gap: 20px;
          align-items: center;
        }
      }
    }

    .i_product-detail-quantity {
      margin-top: 24px;
      font-size: 0.875rem;

      .i_product-detail-quantity-item {
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
          border-bottom: 1px solid #e1dfe1;
        }

        .i_product-detail-quantity-item-name {
          width: 49%;
          padding: 10px 0;
        }

        .i_product-detail-quantity-item-value {
          width: 49%;
          padding: 10px 15px;
          background-color: #f8f8f8;
          border-radius: 15px;
        }
      }
    }

    .i_product-detail-price {
      margin-top: 24px;

      .i_product-detail-price-items {
        display: flex;
        flex-direction: column;
        font-size: 0.875rem;

        .i_product-detail-price-item {
          display: flex;
          justify-content: space-between;

          &.alone-item {
            .i_product-detail-price-item-value {
              border-radius: 15px !important;
            }
          }

          &:first-child {
            padding-top: 0;

            .i_product-detail-price-item-value {
              border-radius: 15px 15px 0 0;
            }
          }

          &:not(:last-child) {
            border-bottom: 1px solid #e1dfe1;
          }

          &:last-child {
            .i_product-detail-price-item-value {
              border-radius: 0 0 15px 15px;
            }
          }

          .i_product-detail-price-item-name {
            width: 49%;
            padding: 10px 0;
          }

          .i_product-detail-price-item-value {
            width: 49%;
            padding: 10px 15px;
            background-color: #f8f8f8;
          }

        }
      }
    }

  }

  .i_show-more {
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
    height: 42px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    &.show {
      .i_show-more-img {
        img {
          transform: rotate(-90deg);
        }
      }
    }

    .i_show-more-img {
      width: 60px;
      height: 42px;
      position: relative;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 12px;
        height: 12px;
        z-index: 2;
        transform: rotate(90deg);
      }

      &::after {
        content: '';
        position: absolute;
        background-color: #f8f8f8;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        z-index: 1;
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #e1dfe1;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

.i_qr-modal{
  width: 100%;
  height: 100%;
  .i_qr-modal-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 150px;
  }
  .i_qr-modal-title{
    font-size: 1.25rem;
    margin-bottom: 25px;
  }
}

.i_modal-favorite-product{
  height: 100%;
  .i_catalog-product{
    width: 100% !important;
  }

  @media screen and (max-width: 1024px){
    .i_catalog-main.empty{
      justify-content: center !important;
      height: 100% !important;
    }
  }
}

.i_empty-list{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.25rem;
  .i_empty-list-title{
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 50px;
    align-items: center;
    img{
      width: 80%;
      height: 80%;
    }
  }

  @media screen and (max-width: 500px){
    font-size: 1rem;
    .i_empty-list-title{
      img{
        width: 60%;
        height: 60%;
      }
    }
  }
}

//MEDIA QUERIES

@media screen and (max-width: 1520px) {
  .i_catalog-page {
    .i_catalog-items {
      .i_catalog-item {
        width: 24%;
      }
    }
  }
  .i_catalog-page-skeleton {
    .react-loading-skeleton {
      width: 24% !important;
    }
  }
}

@media screen and (max-width: 1340px) {
  .i_catalog-page {
    .i_catalog-items {
      .i_catalog-item {
        width: 23%;
      }
    }
  }
  .i_catalog-page-skeleton {
    .react-loading-skeleton {
      width: 23% !important;
    }
  }
}

@media screen and (max-width: 1280px) {
  .i_catalog-page {
    .i_catalog-items {
      .i_catalog-item {
        width: calc(33% - 7px);
      }
    }

    .i_catalog-product {
      .i_catalog-product-head {
        min-width: 1000px;
      }

      .i_catalog-product-items {
        min-width: 1000px;
      }
    }
  }
  .i_catalog-page-skeleton {
    .react-loading-skeleton {
      width: calc(33% - 7px) !important;
    }
  }
}

@media (max-width: 1024px) {
  .i_catalog-page {
    .i_catalog-items {
      padding-bottom: 40px;

      .i_catalog-item {
        width: calc(50% - 7px);
      }
    }
  }
  .i_catalog-page-skeleton {
    .react-loading-skeleton {
      width: calc(50% - 7px) !important;
    }
  }
}

@media (max-width: 768px) {
  .i_catalog-page {
    .i_catalog-items {
      .i_catalog-item {
        width: 100%
      }
    }
  }
  .i_catalog-page-skeleton {
    .react-loading-skeleton {
      width: 100% !important;
    }
  }
}




