.buttonLoader {
  position: relative;
  padding: 10px;
  border-radius: 50px;
  text-decoration: none;
  text-align: center;
  display: block;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  border: 0 solid #6c6cc7;
  color: #fff;
  overflow: hidden;
  cursor: pointer;
  font-size: 90%;
  transition: all .4s ease;
  z-index: 2;
  height: 44px;
  width: 170px;
  align-self: center;

  span {
    position: relative;
    z-index: 3;
  }

  &.loader {
    pointer-events: none;

    &::after {
      content: '';
      width: 0;
    }

    span {
      opacity: 0;
      visibility: hidden;
    }
  }

  &::after {
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(90deg, #6c6cc7, #5757b7);
    z-index: 1;
    height: 100%;
    transition: all .2s ease;
  }

  &::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('icon/loader.svg');
    background-size: 30px;
  }

  &:hover {
    opacity: .8;
  }
}